import * as React from "react";
import * as styles from "./testimonials.module.scss";
import { graphql, StaticQuery } from "gatsby";
import {TestimonialsWrapper, Testimonial as TestimonialType, TestimonialsProps } from "./types";
import Testimonial from "./testimonial";
import Container from "../container/container";
import Heading from "../basic-components/heading/heading";
import { Level } from "react-accessible-headings";
import Button from "../basic-components/button/button";
import { ButtonTypesEnum } from "../basic-components/button/types";
import classNames from "classnames";

const TestimonialsComp: React.FC<TestimonialsProps> = ({
  item,
  limitByPriority,
  limitByLength,
  orderByPriority,
  orderByOrder,
  noMoreButton,
  addPadding,
}: TestimonialsProps) => {
  if (!item) return null;

  let items: TestimonialType[] | undefined = item?.items;
  if (!items) return null;

  if (limitByPriority) {
    items = items.filter((tip: TestimonialType) => tip.priority < limitByPriority);
  }
  if (orderByPriority) {
    items = items.sort((a, b) => (a.priority > b.priority ? 1 : -1));
  }
  if (orderByOrder) {
    items = items.sort((a, b) => (a.order > b.order ? 1 : -1));
  }
  if (limitByLength) {
    items = items.slice(0, limitByLength);
  }
  return (
    <div className={classNames(styles.outerContainer, addPadding ? styles.withPadding : "")}>
      <Container className={styles.midContainer}>
        <Level>
          <Heading>{item.title}</Heading>
          <Level>
            <Heading dangerouslySetInnerHTML={{__html:item.subTitle}} />
          </Level>
          <div className={styles.container}>
            {items.map((node) => (
              <Testimonial key={node.id} item={node} />
            ))}
          </div>
        </Level>
        <div className={styles.moreButtonHolder}>
          {!noMoreButton && (
            <Button type={ButtonTypesEnum.navyBorder} to="/bisolnatur-kullanicilari-anlatiyor/">
              TÜM VİDEOLARI GÖRÜN
            </Button>
          )}
        </div>
        <p className={styles.footNote}>{item.footNote}</p>
      </Container>
    </div>
  );
};

export const Testimonials: TestimonialsWrapper = (props) => (
  <StaticQuery
    query={graphql`
      query TestimonialQuery {
        allTestimonialsJson {
          nodes {
            id
            items {
              description
              id
              ytId
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    breakpoints: [360, 768]
                    placeholder: NONE
                  )
                }
              }
              order
              priority
              writer {
                name
                age
              }
            }
            title
            subTitle
            footNote
          }
        }
      }
    `}
    render={(data) => <TestimonialsComp {...props} item={data.allTestimonialsJson.nodes[0]} />}
  />
);
