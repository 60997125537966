import * as React from "react";
import * as styles from "./testimonials.module.scss";
import { TestimonialProps } from "./types";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import {useContext, useState} from "react";
import {AppContext} from "../../app-provider";
import closeButton from "../../images/cancel_black_36dp.svg";
import Container from "../container/container";


const Testimonial: React.FC<TestimonialProps> = ({ item }: TestimonialProps) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activeYtId, setActiveYtId] = useState("");
  const appContext = useContext(AppContext);

  const openModal = (id: string) => () => {
    if (appContext?.updateState) {
      appContext?.updateState("isMenuActive", true);
    }
    setIsModalOpened(true);
    setActiveYtId(id);
  }
  const closeModal = (event) => {
    event.preventDefault();
    if (appContext?.updateState) {
      appContext?.updateState("isMenuActive", false);
    }
    setIsModalOpened(false);
    setActiveYtId("");
  }
  const {
    ytId,
    description,
    writer: {
      name,
      age
    },
    image: {
      childImageSharp: { gatsbyImageData },
    },
  } = item;

  return (
    <>
      {isModalOpened && (
        <div className={styles.modal}>
          <Container>
            <a href="#" onClick={closeModal} className={styles.closeButton}>
              <img src={closeButton} alt="kapat" width="36" height="36" />
            </a>
            <div className={styles.videoHolder}>
              <iframe width="560" height="315"
                      src={`https://www.youtube.com/embed/${activeYtId}?controls=0&modestbranding=1&iv_load_policy=3&showinfo=0&rel=0`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen />
            </div>
          </Container>
        </div>
      )}
      <div onClick={openModal(ytId)} className={styles.componentHolder}>
        <div className={styles.component}>
          <div className={styles.imageHolder}>
            <GatsbyImage
              className={styles.image}
              loading="lazy"
              image={gatsbyImageData}
              alt={"Deneyim görseli"}
            />
          </div>
          <p>{description}</p>
          <span className={classNames("blue", styles.footer)}>
            <b>{name}, {age}</b>
          </span>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
