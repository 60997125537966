import * as React from "react";
import { PageProps } from "gatsby";
import * as styles from "./oksuruk/styles.module.scss";
import Layout from "../components/layout/layout";
import { Breadcrumb } from "../components/breadcrumb/breadcrumb";
import { Testimonials } from "../components/testimonials/testimonials";
import { Helmet } from "react-helmet";

const PreventionsPage: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout
      stickyHeader={false}
      hideTips={true}
      hideWhereToBuy={true}
      seoConfig={{
        title: "Bisolnatur Kullanıcıları Anlatıyor",
        description:
          "Her 10 Kişiden 9’u Bisolnatur’u Tavsiye Ediyor! (Bağımsız bir araştırma şirketinin; Bisolnatur kullanan 300 kişiyle yaptığı araştırmanın sonuçlarına göre “her 10 anneden 9’u ve her 10 yetişkinden 9’u Bisolnatur’a güveniyor.)",
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Testimonials noMoreButton={true} orderByOrder={true} addPadding={true} />
    </Layout>
  );
};
export default PreventionsPage;
