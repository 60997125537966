// extracted by mini-css-extract-plugin
export var closeButton = "testimonials-module--closeButton--B+5l6";
export var component = "testimonials-module--component--fIxuQ";
export var componentHolder = "testimonials-module--componentHolder--BQTo4";
export var container = "testimonials-module--container--g181q";
export var footNote = "testimonials-module--footNote---1S53";
export var footer = "testimonials-module--footer--JLcGR";
export var image = "testimonials-module--image--0UaFB";
export var imageHolder = "testimonials-module--imageHolder--Odwyz";
export var midContainer = "testimonials-module--midContainer--TD5lu";
export var modal = "testimonials-module--modal--zag-U";
export var moreButtonHolder = "testimonials-module--moreButtonHolder--6sEFY";
export var outerContainer = "testimonials-module--outerContainer--exy5H";
export var videoHolder = "testimonials-module--videoHolder--FkOub";
export var withPadding = "testimonials-module--withPadding--bUDaZ";